
@import url('https://fonts.googleapis.com/css2?family=Karla:wght@200&display=swap');

:root{
    --mainbg:#ffffff;
    --menubarbg: rgb(31 34 30);
}

*{
box-sizing: border-box;
padding: 0;
margin: 0;
font-family: 'Karla', sans-serif;
}

body,html{
    background-color: var(--mainbg);
    margin-top: 31px;
    margin-bottom:50px ;
    user-select: none;
    overscroll-behavior: none;
/* overflow-x:hidden; */
}
a{
    text-decoration: none;
}