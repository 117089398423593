body,
html {
  width: 100vw;
}

html {
  overflow-x: clip;
}

.homepartition {
  display: flex;
  position: fixed;
  /* grid-template-columns: 25vw 75vw; */
  width: -webkit-fill-available;

}

.toplink {
  padding: 4px 8px;
  border: 1px solid white;
  border-radius: 18px;
  font-weight: 900;
  color: white;
  margin: 0 3px
}

#toplinks::-webkit-scrollbar-thumb {
  background: transparent;
}

a:active {
  background: transparent;
}

.pagemenubar {
  width: max-content;
  position: relative;
  overflow-y: scroll;
  height: 100vh;
  width: fit-content;
  padding-top: 50px;
  padding-right: 40px;
  overflow-x: hidden;
  border-right: 1px solid black;

  a {
    color: black;
  }
}

.pagemenubar::-webkit-scrollbar {
  width: 2px;
}

.pagemenubar::-webkit-scrollbar-thumb {
  background: #15ecec;
}

.tg {
  transition: transform .5s;
  transform: translateX(0px);
}

.toggle .pagemenubar {
  transform: translateX(-300px);
}

.pagecontent {
  width: -webkit-fill-available;
  position: relative;
  overflow-y: scroll;
  height: 100vh;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 130px;
}
.celebratebg{
  background: url(../images/congrats.gif);

}

.pagecontent::-webkit-scrollbar {
  width: 5px;
}

.pagecontent::-webkit-scrollbar-track {
  background: #00000000;
}

/* Handle */
.pagecontent::-webkit-scrollbar-thumb {
  background: #e4e4e4;
}

.pagemenubar::-webkit-scrollbar-thumb {
  background: #e4e4e4;
}

/* Handle on hover */
.pagecontent::-webkit-scrollbar-thumb:hover {
  background: #555;
}



section#topmenu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  background: rgb(0 31 255);
  align-items: center;
  z-index: 1000;
  justify-content: center;
  padding: 10px;

}

#topmenu div.topmenu .mainlogo img {
  width: 124px;
  /* filter: invert(1); */
}

#topmenu div.topmenu {
  /* max-width: 100px; */
  padding: 5px 20px;
  display: flex;
  align-items: center;
  position: absolute;
  left: 0px;

}

#topmenu .menubar {
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
}

#topmenu .menubar img {
  width: 25px;
  filter: invert(1);
}

.notification {
  position: absolute;
  right: 20px;
  cursor: pointer;
  filter: invert(1);
}

.notification img {
  width: 30px;
  margin: 5px;
}

section#more {
  /* animation: right 1s linear infinite; */
  display: flex;
  padding-bottom: 70px;
  transform: scale(.9) translateY(-60px);
  width: max-content;
}


section#more.menulist {
  display: flex;
  width: 100%;
}

section#more .menulist ul {
  display: flex;
  flex-direction: column;
  /* padding: 20px 40px; */
  font-family: "Poppins", sans-serif;

}

.goback {
  color: white;
  padding: 10px;
  font-weight: 900;
  font-size: 19px;
  border: 1px solid white;
  border-radius: 10px;
  margin-left: 10px;
  display: inline;
  cursor: pointer;
}

.menulist ul li:hover {
  /* filter: blur(0px); */
  color: #1a83fc;
  border-bottom: 2px solid #272a2c;
}

.menulist ul li {
  list-style: none;
  margin: 5px;
  /* filter: invert(1); */
  cursor: pointer;
  width: 220px;
  position: relative;
  align-items: center;
  padding: 5px;
  border-bottom: 2px solid transparent;
}

.menulist ul li::before {
  content: "";
  position: absolute;
  right: 0;
}

.menulist ul li span {
  display: flex;
  font-size: 19px;
  font-weight: 900;
  gap: 20px;
  width: max-content;
}

.menulist ul li span img {
  width: 25px;
}


#searchbar {
  position: relative;
  display: flex;
  justify-content: center;
}

.searchbar {
  display: flex;
  padding: 5px;
  border-radius: 7px;
  overflow-x: hidden;

}

.searchbar a {
  display: flex;
}

.searchbar img {
  width: 27px;
  margin: 5px 10px;
  filter: invert(1);
}

.searchbar input {
  background-color: transparent;
  color: #ffffff;
  outline: none;
  border: none;
  font-size: 15px;
  font-weight: 700;
  cursor: text;
  width: 100%;
  text-align: left;
  border: 1px solid #898888;
  padding: 0 17px;
  border-radius: 10px;

}



#toplinks {
  display: inline-flex;
  width: 100vw;
  flex-wrap: nowrap;
  overflow-x: scroll;

  .toplink {
    width: max-content;
  }
}

#options {
  position: relative;
  display: flex;
  justify-content: center;
}

.options {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  border-radius: 6px;
  /* background: #1f1d1d; */
  box-shadow: 0 0 4px black;
}

.options div {
  display: flex;
  width: 100%;
}

.options div ul {
  display: flex;
  justify-content: space-evenly;
  width: inherit;
}

.options ul li:hover {
  color: blue;
  filter: drop-shadow(2px 4px 16px #030303);
}

.options ul li {
  list-style: none;
  margin: 10px;
  padding: 4px;
  cursor: pointer;
}

.options ul li span {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  justify-content: center;
  width: max-content;
}

.options ul li span img {
  width: 30px;
  /* mix-blend-mode: multiply; */
  background-color: transparent;
}

.options ul li span p {
  color: rgb(37, 32, 32);
  font-size: 14px;
  font-weight: 900;
  margin: 5px 0px;
}

#winnerlist {
  .container {
    max-width: 800px;
    margin: 50px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .header {
    font-size: 2em;
    margin-bottom: 20px;
    color: #007bff;
  }

  .winner-list {
    list-style-type: none;
    padding: 0;
  }

  .winner-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    margin: 10px 0;
    background-color: #e6f7ff;
    border-radius: 5px;
    transition: transform 0.2s;
    gap: 20px;
  }

  .winner-item:hover {
    transform: scale(1.05);
  }

  .winner-name {
    font-weight: bold;
  }

  .prize {
    color: #ff4500;
    font-size: 1.1em;
    font-weight: bold;
  }
}

.spinner {

  .container {
    text-align: center;
  }

  .wheel-container {
    position: relative;
    width: 300px;
    height: 300px;
    margin: 0 auto;

    &::before {
      content: '🔻';
      position: absolute;
      right: -24px;
      top: 140px;
      transform: rotate(90deg);
    }
  }

  .wheel {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 10px solid #007bff;
    box-sizing: border-box;
    transition: transform 5s ease-out;
    overflow: hidden;
    box-shadow: 3px 0 18px black;


  }

  .wheel-segment {
    width: 50%;
    height: 50%;
    background-color: #ff4500;
    position: absolute;
    top: 50%;
    left: 50%;
    transform-origin: 0% 0%;
    clip-path: polygon(0 0, 100% 0, 100% 100%);
    box-shadow: 0 0 7px 10px black, inset 0 0 10px black;
    span{
      position: absolute;
      rotate: 22deg;
      transform-origin: 1px 36px;
      left: 12px;
      font-weight: 900;
      
    }
  }

  .wheel-segment:nth-child(2n) {
    background-color: #ffd700;
  }

  .wheel-segment:nth-child(3n) {
    background-color: #adff2f;
  }

  .wheel-segment:nth-child(4n) {
    background-color: #00fa9a;
  }

  .prize {
    font-size: 1.5em;
    color: #ff4500;
    font-weight: bold;
    margin-top: 20px;
  }

  .button {
    background-color: #007bff;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-top: 20px;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: inset 0 0 24px black, 0 0 9px #000000;
  }

  .button:disabled {
    background-color: #a1c4fd;
    cursor: not-allowed;
  }

  .pointer {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 20px solid #ff4500;
    position: absolute;
    top: -30px;
    left: calc(50% - 10px);
  }

}

.claimreward {

  .container {
    text-align: center;
  }

  .button {
    background-color: #007bff;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-top: 190px;
    cursor: pointer;
    border-radius: 5px;
  }

  .button:disabled {
    background-color: #a1c4fd;
    cursor: not-allowed;
  }

  .processing {
    font-size: 1.5em;
    color: #007bff;
    font-weight: bold;
    margin-top: 20px;
  }

  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
    padding: 20px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    display: flex;
    flex-direction: column;
    height:100vh;
    padding-top: 100px;
    align-items: center;
  }

  .popup h2 {
    margin-top: 0;
    color: #007bff;
  }

  .popup p {
    font-size: 1.2em;
    color: #333;
  }

  .popup button {
    margin-top: 20px;
    background-color: #ff4500;
    border: none;
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
  }

}

.popup {
  position: fixed;
  top: 70px;
  display: flex;
  justify-content: center;
  left: 0;
  right: 0;
  /* background: url(../images/prizeshine.gif); */
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
  align-items: center;

  img {
    max-width: 230px;
    box-shadow: 0 0 128px 33px blue, 10px 0 51px 10px yellow;
    transition: all 1s
  }
}

.comments {

  overflow-x: scroll;
  height: max-content;

  .container {
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .header {
    font-size: 2em;
    margin-bottom: 20px;
    color: #007bff;
    text-align: center;
  }

  .comment-list {
    list-style-type: none;
    padding: 0;
  }

  .comment-item {
    background-color: #e6f7ff;
    border-radius: 5px;
    margin-bottom: 15px;
    padding: 10px;
    transition: transform 0.2s;
  }

  .comment-item:hover {
    transform: scale(1.02);
  }

  .comment-text {
    font-size: 1.1em;
    color: #333;
    margin-bottom: 5px;
  }

  .comment-author {
    font-weight: bold;
    color: #007bff;
  }

  .reply {
    background-color: #f0f8ff;
    border-left: 3px solid #007bff;
    margin-top: 10px;
    padding: 10px;
    border-radius: 5px;
  }

  .reply-text {
    font-size: 1em;
    color: #333;
  }

  .reply-author {
    font-weight: bold;
    color: #ff4500;
  }
}

/* Celebration.css */
.celebration {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
}

.fireworks {
  position: relative;
  width: 100px;
  height: 100px;
}

.explosion {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border-radius: 50%;
  animation: explosion 1s ease-out infinite;
}

.explosion:nth-child(1) {
  background-color: #ffcc00;
  animation-delay: 0s;
}

.explosion:nth-child(2) {
  background-color: #ff0000;
  animation-delay: 0.2s;
}

.explosion:nth-child(3) {
  background-color: #00ccff;
  animation-delay: 0.4s;
}

@keyframes explosion {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.glow {
  color: #fff;
  font-size: 2em;
  text-align: center;
  animation: glow 1.5s ease-in-out infinite alternate;
}

@keyframes glow {
  0% {
    text-shadow: 0 0 5px #ffcc00, 0 0 10px #ffcc00, 0 0 20px #ffcc00, 0 0 30px #ffcc00, 0 0 40px #ffcc00, 0 0 50px #ffcc00, 0 0 60px #ffcc00, 0 0 70px #ffcc00;
  }
  100% {
    text-shadow: 0 0 10px #ffcc00, 0 0 20px #ffcc00, 0 0 30px #ffcc00, 0 0 40px #ffcc00, 0 0 50px #ffcc00, 0 0 60px #ffcc00, 0 0 70px #ffcc00, 0 0 80px #ffcc00;
  }
}
